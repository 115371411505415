.heading h2,
.heading p {
  font-weight: 700;
}

.heading p {
  font-size: 1.5rem;
}

.heading1 {
  margin-top: 4rem;
}

.project {
  margin: 0 0.3rem 0 0.3rem !important;
}

.card-caption {
  font-weight: 300;
  margin-bottom: 0;
  font-size: 0.75rem;
  letter-spacing: 0.03rem;
}

.card-summary {
  font-weight: 500;
  color: #212529;
  font-size: 1.125rem;
}

.card-summary:hover {
  color: #858585;
}
