.aboutMe {
  margin: 0 1rem 0 1rem;
}

.card {
  /* box-shadow: rgba(0, 0, 0, 0.15) 0px 3px 3px 0px; */
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  border: none !important;
}

.profile {
  border-radius: 50%;
  width: 280px;
  height: 280px;
}

.card-title {
  margin-top: 1rem;
  font-weight: 700;
}

.card-text {
  font-weight: 500;
  font-size: 1.125rem;
}

/* Media screen */
@media only screen and (max-width: 767px) {
  .profile {
    margin-top: 2.5rem;
    border-radius: 50%;
    width: 240px;
    height: 240px;
  }

  .card-title,
  .card-text {
    text-align: center;
  }

  .table {
    width: 250px !important;
    margin: 0 auto;
  }
}
